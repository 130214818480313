import React, { useState, useContext } from "react";

import {
  Box,
  Divider,
  Flex,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "flicket-ui";
import { FormikHelpers } from "formik";
import { useGoogleLogin } from "react-google-login";

import { Footer, Seo, Menu, Layout } from "~components";
import { googleClientId } from "~config";
import { BaseContext } from "~context";
import { useIsMobile, useAccount, useFacebookLogin } from "~hooks";
import { sdk } from "~lib";

import {
  ContentContainer,
  AuthContainer,
  MobileLogo,
  SocialButtons,
  LoginForm,
} from "~components/auth";
import { LoginValues } from "~form/login";
import { RequestMagicLinkInput, SocialAuthType } from "~graphql/sdk";
import { handlePromise, getError, showToast } from "~lib/helpers";
import { useRouter } from "next/router";

const handleMagicLink = async (orgId: string, input: RequestMagicLinkInput) =>
  handlePromise(async () => sdk({ orgId }).requestMagicLink({ input }));

const Login = () => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const { organization } = useContext(BaseContext);
  const [formType, setFormType] = useState<"magic" | "password">("magic");
  const { login, isLoggingIn, handleSocialSuccess } = useAccount();

  const { isFacebookLoading, handleFacebookLogin } = useFacebookLogin({
    onSuccess: handleSocialSuccess,
  });

  const { signIn } = useGoogleLogin({
    onSuccess: (response: any) => {
      handleSocialSuccess(
        response?.getAuthResponse(true)?.access_token,
        SocialAuthType.Google
      );
    },
    clientId: googleClientId,
    onFailure: (response) => {
      if (
        !["popup_closed_by_user", "idpiframe_initialization_failed"].includes(
          response?.error
        )
      ) {
        showToast("Error logging in with Google", "error");
      }
    },
  });

  const handleSubmit = async (
    values: LoginValues[typeof formType],
    actions: FormikHelpers<LoginValues[typeof formType]>
  ) => {
    if (formType === "magic") {
      const magicLinkInput: RequestMagicLinkInput = {
        ...values,
        redirectUrl: decodeURIComponent(
          (router.query.redirect as string) ?? ""
        ),
      };

      const { data, error } = await handleMagicLink(
        organization?.id,
        magicLinkInput
      );

      if (error) {
        showToast(getError(error, "graphQL"), "error");
      }

      if (data) {
        /** @TODO replace toast with persistent msg */
        showToast("An email has been sent", "success");
      }

      actions.setSubmitting(false);
      return;
    }

    const success = await login(values as LoginValues["password"]);

    if (!success) {
      actions.setSubmitting(false);
    }
  };

  const onSubmit = (
    values: LoginValues[typeof formType],
    actions: FormikHelpers<LoginValues[typeof formType]>
  ) => {
    handleSubmit(values, actions);
  };

  return (
    <>
      <Seo
        title="Login"
        description={`Sign in to your ${organization?.name} account.`}
      />
      <Menu />
      <Layout layoutType="auth">
        <ContentContainer>
          <MobileLogo logo={organization?.branding?.logo} />
          <Flex
            bg="N100"
            justifyContent="flex-start"
            flex={1}
            alignItems="center"
            flexDir="column"
          >
            <AuthContainer>
              <Box w="100%">
                <Text
                  fontSize={6}
                  fontWeight="heavy"
                  color="N800"
                  textAlign="center"
                >
                  Log in
                </Text>
                <SocialButtons
                  handleGoogleClick={signIn}
                  isFacebookLoading={isFacebookLoading || isLoggingIn}
                  handleFacebookClick={handleFacebookLogin}
                  isGoogleLoading={isLoggingIn}
                />
                <Box my={2}>
                  <Divider color="N200">
                    <Text fontSize={2} lineHeight={"18px" as any} color="N800">
                      Or
                    </Text>
                  </Divider>
                </Box>
                <Stack
                  flexDir="column"
                  alignItems="flex-start"
                  space={5}
                  mb={3}
                >
                  <RadioGroup
                    toggle
                    value={formType}
                    // @ts-expect-error
                    onChange={setFormType}
                    space={0}
                    big
                  >
                    <Radio value="magic">
                      <Text>Send me a login link</Text>
                    </Radio>
                    <Radio value="password">Password login</Radio>
                  </RadioGroup>
                </Stack>
                <LoginForm formType={formType} handleSubmit={onSubmit} />
              </Box>
            </AuthContainer>
            <Flex
              justifyContent="center"
              alignItems="center"
              mt={2}
              mb={{ _: 3, md: "28px" as any }}
            >
              <Text
                fontSize={1}
                lineHeight="high"
                fontWeight="demiBold"
                mr="1/2"
              >
                No Account yet?
              </Text>
              <Link
                to="/signup"
                color="P300"
                textDecoration="underline"
                fontWeight="heavy"
                fontSize={1}
              >
                Create account
              </Link>
            </Flex>
          </Flex>
          {!isMobile && <Footer />}
        </ContentContainer>
      </Layout>
    </>
  );
};

export default Login;
